<template>
  <div class="card card-style">
    <div class="content">
      <h4 class="mb-2">{{ job.name }}</h4>
      <h5 class="font-13 opacity-30 line-height-s mb-4">
        {{ job.desc }}
      </h5>
      <router-link
        :to="{ name: 'EditExperience', params: { id: job.id } }"
        class="badge color-highlight font-18  p-1"
        ><i class="far fa-edit "></i
      ></router-link>
      <a @click="deleteData(job.id)" class="badge font-18 color-red-dark  p-1"
        ><i class="fas fa-trash-alt"></i></a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "List",
  props: {
    job: Object,
  },
  emits: ["deleteData"],
  methods: {
    deleteData(id) {
      this.$emit("deleteData", id);
    },
  },
};
</script>
