<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18">Pengalaman Kerja</h1>
      <router-link :to="{ name: 'AddExperience' }">
        <span
          class="
            btn
            me-3
            font-18 font-500
            btn-xs
            rounded-xs
            shadow-s
            bg-highlight
          "
        >
          <i class="fas fa-plus select-all font-14 rounded-sm color-white"></i>
          Tambah</span
        >
      </router-link>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <EmptyState
        v-if="listJob.length === 0"
        :message1="'Belum ada Pengalaman kerja'"
        :message2="'yang di tambahkan'"
      />

      <ExperienceList
        v-for="job in listJob"
        :key="job.id"
        :job="job"
        @deleteData="deleteData"
      />
    </div>
    <!-- End Experience List-->

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";
import ExperienceList from "@/components/job_seeker/ExperienceList.vue";
export default {
  name: "JobExperience",
  components: {
    Header,
    Footer,
    EmptyState,
    ExperienceList,
  },
  data() {
    return {
      listJob: [],
      isLoading: true,
      errors: [],
    };
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    async deleteData(id) {
      await axios
        .delete("/api/v1/job-seeker/job/experience/" + id + "/")
        .then((response) => {
          this.getData();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },

    getData() {
      axios
        .get("/api/v1/job-seeker/profile/?field=experience")
        .then((response) => {
          this.listJob = response.data;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
